import { HomeScreen, NotFoundScreen } from "../../modules/base/screens";
import { getRoutes, IRoute } from "./sharedRoutes";
import { t } from "../../translation";
import { ID } from "../interfaces";

type AuthenticatedRoutes =
    | "root"
    | "users"
    | "users-new"
    | "users-detail"
    | "groups"
    | "schedule"
    | "logout"
    | "notFound";

export const getAuthenticatedRoutes = (): {
    [key: string]: IRoute<AuthenticatedRoutes>;
} => ({
    root: {
        component: HomeScreen,
        exact: true,
        path: "/",
        key: "root",
        title: "",
    },
    logout: {
        component: undefined,
        path: "/logout",
        key: "logout",
        title: t("navigation.logout"),
    },
    notFound: {
        component: NotFoundScreen,
        path: undefined,
        key: "notFound",
        title: t("navigation.notFound"),
    },
});

export const getAuthenticatedPath = (
    key: AuthenticatedRoutes,
    params?: { [key: string]: ID }
): string => {
    const routes = getRoutes(getAuthenticatedRoutes());
    if (key in routes && routes[key].path) {
        let route = routes[key].path;

        if (!route) {
            return "/";
        }

        if (params) {
            for (const [_key, value] of Object.entries(params)) {
                route = route.replace(`:${_key}`, value.toString());
            }
        }
        return route;
    }
    return "/";
};
