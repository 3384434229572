import * as React from "react";
import { useHistory } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { BaseLayout } from "../components/BaseLayout";
import { BaseNotFound } from "../components/BaseNotFound";

const styles = StyleSheet.create({
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

export const NotFoundScreen: React.FC = () => {
    const history = useHistory();

    return (
        <BaseLayout disableBreadcrump={true}>
            <div className={css(styles.center)}>
                <BaseNotFound onClick={() => history.push("/")} />
            </div>
        </BaseLayout>
    );
};
