import { globalNormalizedState, globalSchema } from "./schema";
import {
    INormalizedDefinition,
    INormalizedMeta,
    INormalizedMetaMethod,
    NormalizeItemBase,
    SubState,
    subStates,
} from "./interfaces";
import { ID } from "../interfaces";
import { logger } from "../logging";

export const getDefinitionForSubState = (
    subState: SubState
): INormalizedDefinition => {
    return globalNormalizedState[subState];
};

export const generateItemState = (): NormalizeItemBase => {
    const itemState: { [key: string]: { [key: string]: object } } = {};
    subStates.forEach((subState) => (itemState[subState] = {}));
    return itemState as { [key in SubState]: { [key: string]: object } };
};

const metaMethodState: INormalizedMetaMethod = {
    done: false,
    error: null,
    loading: false,
};

// Function to create the base meta state for all the keys in subStates
export const generateMetaState = (): { [key in SubState]: INormalizedMeta } => {
    const metaState: { [key: string]: INormalizedMeta } = {};
    subStates.forEach(
        (subState) =>
            (metaState[subState] = {
                getSingle: metaMethodState,
                get: metaMethodState,
                update: metaMethodState,
                post: metaMethodState,
                delete: metaMethodState,
                currentRecords: 0,
                totalRecords: 0,
                totalPages: 0,
                currentPage: 1,
                activeIds: [],
                selectedIds: [],
                filters: {},
                webSocketEnabled: false,
            })
    );
    return metaState as { [key in SubState]: INormalizedMeta };
};

export const generateNullDict = (): { [key in SubState]: null } => {
    const nullDict: { [key: string]: null } = {};
    subStates.forEach((subState) => (nullDict[subState] = null));
    return nullDict as { [key in SubState]: null };
};

// Function to update the normalized item state with the incoming data
export const updateItemState = (
    newData: NormalizeItemBase,
    existingState: NormalizeItemBase
): NormalizeItemBase => {
    const newState: NormalizeItemBase = { ...existingState };

    for (const subState of subStates) {
        if (subState in newData) {
            newState[subState] = {
                ...newState[subState],
                ...newData[subState],
            };
        }
    }

    return newState;
};

/**
 * Loop over the directions to update the field.
 */
const loopDirection = (
    baseState: NormalizeItemBase,
    newState: { [key: string]: any },
    directions: string[],
    id: ID,
    isArr: boolean,
    entityKey: string
) => {
    const mapper = newState as { [key: string]: any };

    // Take first item of the array
    const direction = directions.shift();

    if (!direction) {
        return mapper;
    }

    const lastItem = directions.length === 0;
    const newMapper = mapper[direction];

    Object.keys(newMapper).forEach((key) => {
        const value = newMapper[key];

        if (!lastItem) {
            if (directions.length === 1 && !isArr) {
                const itemKey = value[directions[0]];
                if (itemKey.toString() === id.toString()) {
                    delete mapper[direction][key];

                    // Also remove the relations for the removed key
                    baseState = removeItemState(
                        entityKey as SubState,
                        key,
                        baseState
                    );
                }
            } else {
                newMapper[key] = loopDirection(
                    baseState,
                    value,
                    directions,
                    id,
                    isArr,
                    entityKey
                );
            }
        } else {
            if (Array.isArray(value)) {
                newMapper[key] = value.filter(
                    (item) => item.toString() !== id.toString()
                );
            } else if (typeof value !== "object") {
                if (value.toString() === id.toString()) {
                    delete newMapper[key];
                }
            } else {
                logger.log("This type isn't support yet", typeof value);
            }
        }
    });

    return mapper;
};

export const removeItemState = (
    subState: SubState,
    id: ID,
    existingState: NormalizeItemBase
): NormalizeItemBase => {
    const newState: NormalizeItemBase = { ...existingState };

    if (id in newState[subState]) {
        delete newState[subState][id];
    }

    const paths = findPaths(subState);
    logger.warn(`Should update ${subState}:`, paths);

    for (const path of paths) {
        const directions = path.path.split(".");
        const mapper = newState as { [key: string]: any };

        loopDirection(
            newState,
            mapper,
            directions,
            id,
            path.isArr,
            path.entityKey
        );
    }

    return newState;
};

interface FoundRoute {
    path: string;
    isArr: boolean;
    entityKey: string;
}

/**
 * Find paths for the passed schema.
 */
const findPath = (
    findSchema: any,
    obj: { [key: string]: any } = globalSchema,
    foundSchemas: string[] = [],
    prefix: string = ""
): FoundRoute[] => {
    const foundKeys: FoundRoute[] = [];

    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const isArr = Array.isArray(value);

        const entity = isArr ? value[0] : value;

        const schema = entity.schema;

        if (entity.key === findSchema.key) {
            foundKeys.push({
                path: `${prefix}.${key}`,
                isArr,
                entityKey: prefix.split(".")[0],
            });
            return;
        }

        if (foundSchemas.includes(entity.key)) {
            return;
        }

        foundSchemas.push(entity.key);
        const keys = findPath(
            findSchema,
            schema,
            foundSchemas,
            `${prefix}${prefix ? "." : ""}${key}`
        );
        foundKeys.push(...keys);
    });

    return foundKeys.filter((key) => !key.path.startsWith("."));
};

/**
 * Find the paths for the relations for the passed SubState
 */
export const findPaths = (subState: SubState) => {
    const schema = globalSchema[subState][0];
    return findPath(schema);
};
