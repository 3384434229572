import * as React from "react";
import { Card, Col, Layout, Row } from "antd";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
    root: {
        display: "flex",
        justifyContent: "center",
        backgroundSize: "cover",
        height: "100vh",
        background: "#3b3b3e",
    },
});

export const BaseUnauthenticatedLayout: React.FC = (props) => (
    <Layout className={css(styles.root)}>
        <Row>
            <Col xs={1} md={3} xl={5} xxl={5} />
            <Col xs={22} md={10} xl={7} xxl={5}>
                <Card className="shadow-xl">{props.children}</Card>
            </Col>
        </Row>
    </Layout>
);
