import * as React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { t } from "../../../../translation";
import { Formik } from "formik";
import { Form, Input, Checkbox } from "formik-antd";
import { Button } from "antd";
import * as Yup from "yup";
import { css, StyleSheet } from "aphrodite";

interface IProps {
    onSubmit: (values: InitialLoginFormData) => void;
    isLoading: boolean;
}

const initialData = {
    username: "",
    password: "",
    remember: true,
};

export type InitialLoginFormData = typeof initialData;

const styles = StyleSheet.create({
    horizontal: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    button: {
        width: "100%",
    },
});

export const LoginForm: React.FC<IProps> = (props: IProps) => (
    <Formik<InitialLoginFormData>
        initialValues={initialData}
        onSubmit={props.onSubmit}
        validationSchema={Yup.object().shape({
            username: Yup.string().required(),
            password: Yup.string().required(),
        })}
    >
        <Form>
            <Form.Item name="username">
                <Input
                    name="username"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("users.fields.username")}
                    data-cy="input-username"
                />
            </Form.Item>
            <Form.Item name="password">
                <Input
                    name="password"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("users.fields.password")}
                    data-cy="input-password"
                />
            </Form.Item>
            <div className={css(styles.horizontal)}>
                <Form.Item name="remember" valuePropName="checked">
                    <Checkbox name="remember">
                        {t("users.auth.rememberMe")}
                    </Checkbox>
                </Form.Item>

                <Link to={getUnAuthenticatedPath("reset")}>
                    {t("users.auth.passwordReset.title")}
                </Link>
            </div>

            <Button
                type="primary"
                htmlType="submit"
                loading={props.isLoading}
                className={css(styles.button)}
                data-cy="button-submit"
            >
                {t("users.auth.title")}
            </Button>
        </Form>
    </Formik>
);
