/**
 * Remove unwanted properties from the object
 * properties that start with an underscore.
 * properties that are of type moment.ts js (TimeStamped Models)
 */
export const removeUnwantedProperties = <T>(obj: any): Partial<T> => {
    if (!obj) {
        return obj;
    }
    const propNames = Object.getOwnPropertyNames(obj);
    for (const propName of propNames) {
        if (Array.isArray(obj[propName])) {
            for (let i = 0; i < obj[propName].length; i++) {
                obj[propName][i] = removeUnwantedProperties(obj[propName][i]);
            }
        }
        if (typeof obj[propName] === "object") {
            obj[propName] = removeUnwantedProperties(obj[propName]);
        }
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
        if (propName.startsWith("__")) {
            delete obj[propName];
        }
        if (propName.startsWith("created") || propName.startsWith("modified")) {
            delete obj[propName];
        }
    }
    return obj;
};
