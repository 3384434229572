// tslint:disable:no-submodule-imports
import * as React from "react";
import nlNl from "antd/es/locale/nl_NL";
import enUs from "antd/es/locale/en_US";
import { ConfigProvider } from "antd";
import { useStoreState } from "../../../../store/hooks";

interface IProps {
    children: React.ReactElement;
}

export const BaseProvider: React.FC<IProps> = (props) => {
    const language =
        useStoreState((state) => state.auth.currentUser?.language) || "NL";
    const locale = language === "NL" ? nlNl : enUs;

    return <ConfigProvider locale={locale}>{props.children}</ConfigProvider>;
};
