import * as React from "react";
import { Employee } from "../../../employee/models";
import { Card } from "antd";
import { css, StyleSheet } from "aphrodite";

interface IProps {
    employee: Employee;
    onDelete: () => void;
}

const styles = StyleSheet.create({
    card: {
        margin: "0.5em",
        cursor: "pointer",
        textAlign: "center",
    },
});

export const EmployeeCard: React.FC<IProps> = (props) => (
    <Card
        className={`card-delete ${css(styles.card)}`}
        onClick={() => {
            props.onDelete();
        }}
    >
        {props.employee.name}
    </Card>
);
