import { Schema, schema } from "normalizr";
import { User, Group } from "../../modules/auth/models";
import { INormalizedDefinition, SubState } from "./interfaces";
import { Employee } from "../../modules/employee/models";

// Normalizer Schema's
export const groupSchema = new schema.Entity<Group>("groups");
export const userSchema = new schema.Entity<User>("users");
export const employeeSchema = new schema.Entity<Employee>("employees");

// Separate lines for definitions to prevent circular references
userSchema.define({
    groups: [groupSchema],
});

export const globalSchema: { [key in SubState]: Schema[] } = {
    users: [userSchema],
    groups: [groupSchema],
    employees: [employeeSchema],
};

export const globalNormalizedState: {
    [key in SubState]: INormalizedDefinition;
} = {
    users: {
        model: User,
        schema: userSchema,
        baseUrl: "users",
    },
    groups: {
        model: Group,
        schema: groupSchema,
        baseUrl: "groups",
    },
    employees: {
        model: Employee,
        schema: employeeSchema,
        baseUrl: "employees",
    },
};
