import Axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import applyConverters from "axios-case-converter";
import { getDifference } from "../functions";
import { logger } from "../logging";
import { normalize, Schema } from "normalizr";
import { plainToClass } from "class-transformer";
import { keysToSnake } from "../functions/caseCovertion";

export interface IRequestParam {
    obj?: any;
    schema?: Schema;
    config?: AxiosRequestConfig;
}

export interface IPatchParam extends IRequestParam {
    // Pass the original object to only patch the difference between the two.
    original?: any;
}

export const getClient = (): AxiosInstance => {
    const client: AxiosInstance = Axios.create();
    // @ts-ignore because of an axios version mismatch.
    return applyConverters(client);
};

export class HttpClient {
    public static get<Output>(
        url: string,
        params?: IRequestParam
    ): Promise<Output> {
        return getClient()
            .get<Output>(url, params ? params.config : undefined)
            .then((response) => {
                return HttpClient.getResponse<Output>(
                    response,
                    params?.obj,
                    params?.schema
                );
            })
            .catch((error: Error) => {
                logger.error(error);
                throw error;
            });
    }

    public static put<Output>(
        url: string,
        data: object,
        params?: IRequestParam
    ): Promise<Output> {
        return getClient()
            .put<Output>(url, data, params ? params.config : undefined)
            .then((response) => {
                return HttpClient.getResponse<Output>(
                    response,
                    params?.obj,
                    params?.schema
                );
            })
            .catch((error: Error) => {
                logger.error(error.message);
                throw error;
            });
    }

    public static patch<Output>(
        url: string,
        data: object,
        params?: IPatchParam
    ): Promise<Output> {
        const payload = keysToSnake(getDifference(params?.original, data));

        return getClient()
            .patch<Output>(url, payload, params ? params.config : undefined)
            .then((response) => {
                return HttpClient.getResponse<Output>(
                    response,
                    params?.obj,
                    params?.schema
                );
            })
            .catch((error: AxiosError) => {
                logger.error(error.message);
                throw error;
            });
    }

    public static post<Output>(
        url: string,
        data: object,
        params?: IRequestParam
    ): Promise<Output> {
        return getClient()
            .post<Output>(url, data, params ? params.config : undefined)
            .then((response) => {
                return HttpClient.getResponse(
                    response,
                    params?.obj,
                    params?.schema
                );
            })
            .catch((error: Error) => {
                logger.error(error);
                throw error;
            });
    }

    public static delete<Output>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<Output> {
        return getClient()
            .delete<Output>(url, config)
            .then((response) => response.data)
            .catch((error: Error) => {
                logger.error(error);
                throw error;
            });
    }

    private static getResponse<Output>(
        response: AxiosResponse<Output>,
        obj: any,
        schema: any
    ): Output {
        if (schema) {
            const responseData = HttpClient.parseResponse(response.data);
            const normalizedData = normalize(responseData, schema).entities;

            if (Array.isArray(responseData)) {
                // @ts-ignore
                return {
                    data: normalizedData,
                    // @ts-ignore
                    currentPage: response.data.currentPage,
                    // @ts-ignore
                    totalPages: response.data.totalPages,
                    // @ts-ignore
                    totalRecords: response.data.totalRecords,
                    orderedIds: responseData.map((row) => row.id),
                };
            }

            // @ts-ignore
            return normalizedData;
        } else if (obj) {
            return plainToClass(obj, response.data);
        }
        return response.data;
    }

    private static parseResponse(responseData: any): any {
        if (!responseData) {
            return responseData;
        }
        if (Array.isArray(responseData)) {
            // Check if the responseData is an array.
            return responseData;
        } else if (
            "next" in responseData &&
            "previous" in responseData &&
            "results" in responseData
        ) {
            return responseData.results;
        } else if (
            "id" in responseData ||
            "uuid" in responseData ||
            "file" in responseData
        ) {
            return responseData;
        }

        logger.error("Not able to parse response", responseData);
        return {};
    }
}
