import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { Notifier } from "../../../shared/functions";

export interface ILoginOutputData {
    token: string;
}

interface IInputData {
    userId: number;
}

export const hijackLoginThunk: Thunk<
    IAuthState,
    IInputData,
    any,
    RootState,
    Promise<ILoginOutputData>
> = thunk(async (actions, payload, { getStoreActions }) => {
    actions.loginBegin();

    return HttpClient.post<ILoginOutputData>(`/api/hijack/`, {
        user_id: payload.userId,
    })
        .then((data) => {
            actions.loginSuccess();

            actions.setTokenData({
                token: data.token,
                remember: true,
                hijack: true,
            });
            getStoreActions().auth.getCurrentUser();
            Notifier.success("users.auth.login.success");
            return data;
        })
        .catch((error) => {
            actions.loginFailure(error);
            actions.setTokenData({
                token: null,
                remember: false,
            });
            Notifier.error("users.auth.login.wrongCredentials");
            throw error;
        });
});
