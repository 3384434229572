import * as React from "react";
import { BaseLayout } from "../components/BaseLayout";
import { ScheduleForm } from "../../schedule/components/ScheduleForm";
import { Button, DatePicker, Form } from "antd";
import moment from "moment";
import { t } from "../../../translation";
import { BaseTour } from "../components/BaseTour";
import { useLocalStorage } from "../hooks";

export const HomeScreen: React.FC = () => {
    const [startDate, setStartDate] = React.useState(
        moment()
            .startOf("week")
            .add(1, "week")
    );
    const [isTourOpen, setIsTourOpen] = useLocalStorage("isTourOpen", true);

    const maxDate = moment()
        .startOf("week")
        .add(4, "weeks");

    return (
        <BaseLayout fluid={true}>
            <BaseTour
                isOpen={isTourOpen}
                onClose={() => setIsTourOpen(false)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item label={"Week"} className="form-week-picker">
                    <DatePicker
                        value={startDate}
                        onChange={(value) => setStartDate(value!)}
                        picker="week"
                        allowClear={false}
                        disabledDate={(date) => date >= maxDate}
                    />
                </Form.Item>
                <Button onClick={() => setIsTourOpen(true)}>
                    {t("schedule.help.title")}
                </Button>
            </div>
            <ScheduleForm date={startDate} />
        </BaseLayout>
    );
};
