import { denormalize } from "normalizr";
import {
    getDefinitionForSubState,
    globalSchema,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { plainToClass } from "class-transformer";
import { RootState } from "../../../store/storeModel";
import { State } from "easy-peasy";
import { ID } from "../../../shared/interfaces";

/***
 * Gets all the items for the given subState
 * @param state The state from where the items should be received
 * @param subState for which the items should be received
 */
export const getItemsSelector = <Model>(
    state: State<RootState>,
    subState: SubState
) =>
    getTransformedItems<Model>(
        state.normalize.items,
        subState,
        Object.keys(state.normalize.items[subState])
    );

/***
 * Gets all the active items for the given subState based on the activeIds
 * @param state The state from where the items should be received
 * @param subState for which the items should be received
 */
export const getActiveItems = <Model>(
    state: State<RootState>,
    subState: SubState
) =>
    getTransformedItems<Model>(
        state.normalize.items,
        subState,
        state.normalize.meta[subState].activeIds
    );

/***
 * Gets the items for the given ids and subState
 * @param state The state from where the items should be received
 * @param subState for which the items should be received
 * @param ids: array of ids which items should be retrieved
 */
export const getItemsWithIds = <Model>(
    state: State<RootState>,
    subState: SubState,
    ids: ID[]
) => getTransformedItems<Model>(state.normalize.items, subState, ids);

/***
 * Gets the items for the given ids and subState
 * @param state The state from where the items should be received
 * @param subState for which the items should be received
 * @param id: id of the item which should be retrieved
 */
export const getItemSelector = <Model>(
    state: State<RootState>,
    subState: SubState,
    id: ID
) => {
    const items = getTransformedItems<Model>(state.normalize.items, subState, [
        id,
    ]);
    if (items.length) {
        return items[0];
    }

    return null;
};

/**
 * Get tansformed models based on the Model, state, subState and query
 * @param normalizedState: The state in which the models are
 * @param subState: for which the models should be received
 * @param query: The query which will be used to find the models
 */
const getTransformedItems = <Model>(
    normalizedState: NormalizeItemBase,
    subState: SubState,
    query: any
): Model[] => {
    const model = getDefinitionForSubState(subState).model;

    const denormalizedItems = denormalize(
        {
            [subState]: query,
        },
        globalSchema,
        normalizedState
    )[subState];

    return plainToClass<Model, any>(model, denormalizedItems, {
        enableCircularCheck: true,
    }).filter((item: Model) => !!item);
};
