import { SubState, subStates } from "../../../../shared/normalizer";
import * as React from "react";

export * from "./BaseForm";
export * from "./FormDatePickerItem";
export * from "./FormSwitchItem";
export * from "./FormInputItem";
export * from "./FormTextAreaItem";
export * from "./FormInputNumberItem";
export * from "./FormSelectItem";

/**
 * Returns dict with keys for each SubState with a function with accepts and returns the same input.
 */
const getDefaultCleaners = () => {
    const val: { [key: string]: (input: any) => any } = {};
    subStates.forEach((subState) => (val[subState] = (input) => input));
    return val as { [key in SubState]: (input: any) => any };
};

/**
 * Dict with all the SubStates with a cleaner function.
 */
export const FormCleaners: { [key in SubState]: (input: any) => any } = {
    ...getDefaultCleaners(),
};

export const FormContext = React.createContext({ readOnly: false });
