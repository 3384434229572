import { Group } from "./Group";
import { Transform, Type } from "class-transformer";
import { BaseModel } from "../../normalized/models";
import { SubState } from "../../../shared/normalizer";
import moment, { Moment } from "moment";
import { toSnake } from "../../../shared/functions/caseCovertion";

export type PermissionType = "add" | "view" | "delete" | "change";

export class User extends BaseModel {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    isSuperuser: boolean;
    language: "EN" | "NL";

    @Transform((value) => (value ? moment(value) : null))
    lastLogin: Moment | null;

    permissions: string[];

    @Type(() => Group)
    groups: Group[];

    fullName(): string {
        return `${this.firstName}${this.firstName && " "}${this.lastName}`;
    }

    /**
     * Checks if the user has permission for the passed subState and permission type
     * @param subState SubState to check permissions for (ex. groups)
     * @param permission PermissionType to check permissions for (ex. add)
     */
    hasPermission(subState: SubState, permission: PermissionType): boolean {
        // Remove last character to get the base
        const subStateLower = toSnake(subState).replace("_", "");
        const subStateBase = subStateLower.endsWith("s")
            ? subStateLower.substr(0, subState.length - 1)
            : subStateLower;
        return this.permissions.includes(`${permission}_${subStateBase}`);
    }
}
