/***
 * Converts the snake case based string to camel case
 * @param input string which should be converted
 */
export const toCamel = (input: string): string =>
    input.replace(/([-_][a-z])/gi, ($1) => {
        return $1
            .toUpperCase()
            .replace("-", "")
            .replace("_", "");
    });

/***
 * Converts the camel case based string to snake case
 * @param input string which should be converted
 */
export const toSnake = (input: string): string =>
    input
        .split(/(?=[A-Z])/)
        .join("_")
        .toLowerCase();

/***
 * Converts keys from dict to camel case
 * @param input dict with snake case keys
 */
export const keysToCamel = (input: { [key: string]: any }): object => {
    const newObject: { [key: string]: object } = {};

    Object.keys(input).forEach((k) => {
        newObject[toCamel(k)] =
            typeof input[k] === "object" &&
            !Array.isArray(input[k]) &&
            input[k] !== null
                ? keysToCamel(input[k])
                : input[k];
    });

    return newObject;
};

/***
 * Converts keys from dict to snake case
 * @param input dict with camel case keys
 */
export const keysToSnake = (input: {
    [key: string]: any;
}): { [key: string]: any } => {
    const newObject: { [key: string]: object } = {};

    Object.keys(input).forEach((k) => {
        newObject[toSnake(k)] =
            typeof input[k] === "object" &&
            !Array.isArray(input[k]) &&
            input[k] !== null
                ? keysToSnake(input[k])
                : input[k];
    });

    return newObject;
};
