import { buttonsTranslationEn } from "./buttons";
import { usersTranslationEn } from "./users";
import { filtersTranslationEn } from "./filters";
import { navigationTranslationEn } from "./navigation";
import { groupsTranslationEn } from "./groups";
import { formsTranslationEn } from "./forms";
import { httpTranslationEn } from "./http";
import { homeTranslationEn } from "./home";
import { scheduleTranslationEn } from "./schedule";

export const TranslationEn = {
    buttons: buttonsTranslationEn,
    users: usersTranslationEn,
    filters: filtersTranslationEn,
    navigation: navigationTranslationEn,
    groups: groupsTranslationEn,
    forms: formsTranslationEn,
    http: httpTranslationEn,
    home: homeTranslationEn,
    schedule: scheduleTranslationEn,
};
