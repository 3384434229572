import * as React from "react";
import { SubState } from "../../../../shared/normalizer";
import { useStoreState } from "../../../../store/hooks";
import { PermissionType } from "../../../auth/models";
import { Button, Result, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { Notifier } from "../../../../shared/functions";
import { StyleSheet, css } from "aphrodite";

interface IProps {
    permission: PermissionType;
    subState: SubState;
    showMessage?: boolean;
    loading?: boolean;
}

const styles = StyleSheet.create({
    spinWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10em",
    },
});

export const BasePermissionWrapper: React.FC<IProps> = (props) => {
    const history = useHistory();
    const currentUser = useStoreState((state) => state.auth.currentUser);

    if (props.loading) {
        return (
            <div className={css(styles.spinWrapper)}>
                <Spin />
            </div>
        );
    }

    if (currentUser?.hasPermission(props.subState, props.permission)) {
        return <>{props.children}</>;
    }

    if (props.showMessage) {
        return (
            <Result
                status="403"
                title="No permission for this page"
                extra={
                    <>
                        <Button
                            type="ghost"
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            Go Home
                        </Button>
                        <Button
                            type="primary"
                            key="console"
                            onClick={() => {
                                Notifier.warning(
                                    "This feature isn't available yet"
                                );
                            }}
                        >
                            Request access
                        </Button>
                    </>
                }
            />
        );
    }

    return null;
};
