import { formatBackendValidationErrors } from "../../helpers/baseFormik";
import { Form, SubmitButton } from "formik-antd";
import { Formik, FormikConfig, FormikHelpers, FormikProps } from "formik";
import * as React from "react";
import { SubState } from "../../../../shared/normalizer";
import { useStoreActions } from "../../../../store/hooks";
import * as Yup from "yup";
import { logger } from "../../../../shared/logging";
import { ID } from "../../../../shared/interfaces";
// tslint:disable-next-line:no-submodule-imports
import { FormProps } from "antd/lib/form/Form";
import { Col, Row } from "antd";
import { css, StyleSheet } from "aphrodite";
import { t } from "../../../../translation";
import { FormCleaners, FormContext } from "./index";

type InitialDataObject = { id: ID | null };

interface IProps<T extends InitialDataObject> {
    /**
     * The SubState for which the form is created.
     */
    subState: SubState;
    /**
     * The initialValues for which the form is created.
     */
    initialValues: T;
    /**
     * The validationSchema for the form.
     */
    validationSchema: Yup.ObjectSchema<T>;
    /**
     * This function will be called after the form is submitted.
     * @param values: The new values.
     */
    onClose?: (values: T) => void;
    /**
     * Extra props for the Formik component.
     */
    extraFormikProps?: Partial<FormikConfig<T>>;
    /**
     * Extra props for the Form component.
     */
    extraFormProps?: FormProps;
    /**
     * If the Submit button should NOT be rendered.
     */
    disableSubmitButton?: boolean;
    /**
     * Disable the whole form for readOnly state.
     */
    readonly?: boolean;
    /**
     * Function will be triggered on render.
     */
    onRender?: (helpers: FormikProps<T>) => void;
}

const styles = StyleSheet.create({
    submit: {
        width: "100%",
    },
});

export const BaseForm = <T extends InitialDataObject>(
    props: React.PropsWithChildren<IProps<T>>
) => {
    const { createItem, updateItem } = useStoreActions(
        (actions) => actions.normalize
    );

    const onSubmit = (values: T, formikHelpers: FormikHelpers<T>): void => {
        const create: boolean = values.id === null;

        // Clean form if there is a cleaner available for the subState.
        const cleanedValues =
            props.subState in FormCleaners
                ? FormCleaners[props.subState](values)
                : values;

        logger.info(
            props.subState,
            create ? "create" : "update",
            cleanedValues
        );

        const onSuccess = () => {
            formikHelpers.setSubmitting(false);
            if (props.onClose) {
                props.onClose(cleanedValues);
            }
        };

        const onError = (error: any) => {
            if (error.response && error.response.status === 400) {
                const validationErrors = formatBackendValidationErrors<T>(
                    error.response.data
                );
                formikHelpers.setErrors(validationErrors);
            }
            formikHelpers.setSubmitting(false);
        };

        if (cleanedValues.id === null) {
            createItem({
                subState: props.subState,
                data: cleanedValues,
            })
                .then(onSuccess)
                .catch(onError);
        } else {
            updateItem({
                subState: props.subState,
                id: cleanedValues.id,
                data: cleanedValues,
                originalObject: props.initialValues,
            })
                .then(onSuccess)
                .catch(onError);
        }
    };

    return (
        <Formik<T>
            onSubmit={onSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            {...props.extraFormikProps}
        >
            {(helpers) => (
                <FormContext.Provider value={{ readOnly: !!props.readonly }}>
                    <Form
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        {...props.extraFormProps}
                    >
                        {props.onRender && props.onRender(helpers)}
                        {props.children}
                        {!props.disableSubmitButton && !props.readonly && (
                            <Row>
                                <Col xs={0} md={14} xl={16} xxl={18} />
                                <Col xs={24} md={10} xl={8} xxl={6}>
                                    <SubmitButton
                                        className={css(styles.submit)}
                                        data-cy="button-submit"
                                    >
                                        {!!helpers.values.id
                                            ? t("buttons.edit")
                                            : t("buttons.create")}
                                    </SubmitButton>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </FormContext.Provider>
            )}
        </Formik>
    );
};
