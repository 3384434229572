import { LocaleObject } from "yup";

/* eslint-disable */

// tslint:disable:no-invalid-template-strings
const yupValidation: LocaleObject = {
    mixed: {
        default: "Dit veld is invalide!",
        required: "Dit veld is verplicht!",
        oneOf: "Dit veld moet een van de volgende waardes zijn: ${values}!",
        notOneOf: "Dit veld moet geen van de volgende waardes zijn: ${values}!",
        notType: "Dit veld moet niet van het volgende type zijn: ${type}",
    },
    number: {
        min: "De waarde moet minimaal ${min} zijn!",
        max: "De waarde mag maximaal ${max} zijn!",
        negative: "Het ingevoerde getal [${value}] moet negatief zijn!",
        positive: "Het ingevoerde getal [${value}] moet positief zijn!",
        integer: "De ingevoerde waarde [${value}] is geen getal!",
        lessThan: "De ingevoerde waarde moet kleiner zijn!",
        moreThan: "De ingevoerde waarde moet groter zijn!",
    },
    string: {
        min: "Dit veld moet minimaal ${min} characters bevatten!",
        max: "Dit veld mag maximaal ${max} characters bevatten!",
        email: "Dit veld is geen geldig email adres!",
        length: "Dit veld moet exact ${length} characters hebben!",
        matches: 'Dit veld moet voldoen aan de volgende: "${regex}!"',
        url: "Dit veld moet een valide URL zijn!",
        trim: "Dit veld moet een getrimde waarde zijn!",
        lowercase: "Dit veld mag geen hoofdletters bevatten!",
        uppercase: "Dit veld mag alleen hoofdletters bevatten!",
    },
    date: {
        min: "Dit veld moet later zijn dan ${min}!",
        max: "Dit veld moet eerder zijn dan ${max}!",
    },
    array: {
        min: "Dit veld moet minimaal ${min} items bevatten!",
        max: "Dit veld mag maximaal ${max} items bevatten!",
    },
    boolean: {},
    object: {
        noUnknown: "Dit veld heeft niet gespecificeerde velden: ${unknown}!",
    },
};

export const formsTranslationNl = {
    empty: "Leeg",
    validation: {
        yup: yupValidation,
    },
    yes: "Ja",
    no: "Nee",
    unknown: "Onbekend",
    add: "Toevoegen",
    edit: "Aanpassen",
};
