import { logger } from "../logging";
import { globalNormalizedState, SubState } from "../normalizer";

export type WebSocketStatus = "CONNECTING" | "OPEN" | "CLOSING" | "CLOSED";

export class WsClient {
    subState: SubState;
    status: WebSocketStatus;

    socket: WebSocket;

    onMessage?: (event: MessageEvent, subState: SubState) => void;

    constructor(
        subState: SubState,
        onMessage: (event: MessageEvent, subState: SubState) => void
    ) {
        this.subState = subState;
        this.status = "CONNECTING";

        this.open();
        this.linkOnMessageEvent(onMessage);
    }

    open() {
        if (!("WebSocket" in window)) {
            throw Error("WebSocket not supported by your browser");
        }

        const WebsocketUrl =
            process.env.REACT_APP_WEBSOCKET ||
            `wss://${window.location.hostname}`;

        const baseUrl = globalNormalizedState[this.subState].baseUrl;

        const token = localStorage.getItem("token");
        document.cookie = "authorization=" + token + ";";
        this.socket = new WebSocket(`${WebsocketUrl}/ws/${baseUrl}/`);

        this.createLoggerEventListeners();
        this.createStatusEventListeners();
    }

    createStatusEventListeners() {
        // Event listeners to keep status realtime
        this.socket.addEventListener("open", () => this.updateStatus());
        this.socket.addEventListener("error", () => this.updateStatus());
        this.socket.addEventListener("close", () => this.updateStatus());
    }

    createLoggerEventListeners() {
        // Temporary logging events
        this.socket.onopen = () => {
            logger.log(this.subState, "Opened connection");
        };
        this.socket.onerror = (error) => {
            logger.log(this.subState, "Error", error);
        };

        this.socket.onclose = () => {
            logger.log(this.subState, "Closed connection");
        };
    }

    linkOnMessageEvent(
        onMessage: (event: MessageEvent, subState: SubState) => void
    ) {
        this.socket.onmessage = (event) => onMessage(event, this.subState);
        this.onMessage = onMessage;
    }

    updateStatus() {
        if (!this.socket) return;

        switch (this.socket.readyState) {
            case this.socket.CONNECTING:
                this.status = "CONNECTING";
                break;
            case this.socket.OPEN:
                this.status = "OPEN";
                break;
            case this.socket.CLOSING:
                this.status = "CLOSING";
                break;
            case this.socket.CLOSED:
                this.status = "CLOSED";
                break;
        }
    }

    isConnectingOrOpen() {
        return this.status === "CONNECTING" || this.status === "OPEN";
    }

    isClosed() {
        return this.status === "CLOSED";
    }
}
