import { WsClient } from "./WsClient";
import { SubState, subStates } from "../normalizer";

const INTERVAL_CHECK_TIMEOUT = 5000;

export class WsManager {
    wsClients: WsClient[];
    closedSubStates: SubState[];

    constructor() {
        this.wsClients = [];
        this.closedSubStates = [];

        // Add interval to check lost connections
        setInterval(() => this.intervalCheck(), INTERVAL_CHECK_TIMEOUT);
    }

    checkEnabledWebSockets(
        webSocketsEnabled: {
            [key in SubState]: boolean;
        },
        onMessage: (event: MessageEvent, subState: SubState) => void
    ) {
        subStates.forEach((subState) => {
            const webSocketEnabled = webSocketsEnabled[subState];
            const client = this.getClient(subState);

            if (!webSocketEnabled) {
                if (client) {
                    // If websocket is not enabled but has existing client close connection and delete the client
                    this.closeAndRemove(subState);
                }
                return;
            } else if (client) {
                return;
            }
            this.createClient(subState, onMessage);
        });
    }

    createClient(
        subState: SubState,
        onMessage: (event: MessageEvent, subState: SubState) => void
    ): WsClient {
        const newClient = new WsClient(subState, onMessage);
        this.wsClients.push(newClient);

        return newClient;
    }

    hasClient(subState: SubState): boolean {
        return !!this.getClient(subState);
    }

    getClient(subState: SubState): WsClient | undefined {
        return this.wsClients.find(
            (wsClient) => wsClient.subState === subState
        );
    }

    close(subState: SubState): void {
        const client = this.getClient(subState);
        if (!client) return;
        client.socket.close();
    }

    closeAndRemove(subState: SubState): void {
        this.close(subState);
        this.wsClients = this.wsClients.filter(
            (wsClient) => wsClient.subState !== subState
        );
    }

    reConnect(subState: SubState) {
        const client = this.getClient(subState);
        if (!client || !client.onMessage) return;
        const onMessage = client.onMessage;
        this.closeAndRemove(subState);
        this.createClient(subState, onMessage);
    }

    intervalCheck() {
        this.closedSubStates = this.wsClients
            .filter((wsClient) => wsClient.isClosed())
            .map((wsClient) => wsClient.subState);

        this.closedSubStates.forEach((subState) => {
            this.reConnect(subState);
        });
    }
}
