import { BaseModel } from "./baseModel";
import { Exclude } from "class-transformer";

export class TimeStampedModel extends BaseModel {
    @Exclude({ toPlainOnly: true })
    created: string;

    @Exclude({ toPlainOnly: true })
    modified: string;
}
