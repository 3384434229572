import * as React from "react";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";
import { AuthGuard } from "./modules/auth/components";
import { BaseRouter, WsController } from "./modules/base/components";
import { BrowserRouter as Router } from "react-router-dom";
import { BaseProvider } from "./modules/base/components/BaseProvider";

import "./styles/antd.less";

export const App = () => {
    return (
        <StoreProvider store={store}>
            <BaseProvider>
                <Router>
                    <AuthGuard />
                    <WsController />
                    <BaseRouter />
                </Router>
            </BaseProvider>
        </StoreProvider>
    );
};
