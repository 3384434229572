export const weekStatuses = [
    "open",
    "pending",
    "rejected",
    "accepted",
] as const;

export type WeekStatus = typeof weekStatuses[number];

export class Schedule {
    start: string;
    end: string;
    status: WeekStatus;
    dates: { date: string; employeeIds: string[] }[];
}
