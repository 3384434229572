import { thunk, Thunk } from "easy-peasy";
import { INormalizeState } from "../state";
import { RootState } from "../../../store/storeModel";
import { NormalizeItemBase, SubState } from "../../../shared/normalizer";

interface IPayload {
    subState: SubState;
    enable: boolean;
}

export const setWebSocketThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<NormalizeItemBase>
> = thunk((actions, payload) => {
    actions.setWebSocketSuccess({
        subState: payload.subState,
        enable: payload.enable,
    });
    return new Promise((resolve) => resolve());
});
