import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { INormalizeState } from "../state";
import { logger } from "../../../shared/logging";
import {
    getDefinitionForSubState,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { classToPlain } from "class-transformer";
import { Notifier } from "../../../shared/functions";

interface IPayload {
    subState: SubState;
    data: object;
    addToActive?: boolean;
}

/**
 * Generic function to create an item and put it in the normalized state
 */
export const createItemThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<NormalizeItemBase>
> = thunk((actions, payload) => {
    actions.createItemBegin({ subState: payload.subState });
    const definition = getDefinitionForSubState(payload.subState);
    const plainData = classToPlain(payload.data);

    return HttpClient.post<NormalizeItemBase>(
        `/api/${definition.baseUrl}/`,
        plainData,
        {
            schema: definition.schema,
        }
    )
        .then((data) => {
            Notifier.success("http.crud.create.success");
            actions.createItemSuccess({
                data,
                subState: payload.subState,
                addToActive: payload.addToActive,
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            Notifier.error("http.crud.create.error");
            actions.createItemFailure({ error, subState: payload.subState });
            throw error;
        });
});
