import { action, Action } from "easy-peasy";
import {
    NormalizeMetaBase,
    NormalizeItemBase,
    SubState,
    FilterData,
    generateItemState,
    generateMetaState,
    updateItemState,
    removeItemState,
} from "../../shared/normalizer";
import { getItemsThunk } from "./thunks/getItemsThunk";
import { getItemThunk } from "./thunks/getItemThunk";
import { updateItemThunk } from "./thunks/updateItemThunk";
import { deleteItemThunk } from "./thunks/deleteItemThunk";
import { createItemThunk } from "./thunks/createItemThunk";
import { ID } from "../../shared/interfaces";
import { setWebSocketThunk } from "./thunks/setWebSocketThunk";

export interface INormalizeState {
    /**
     * State
     */
    items: NormalizeItemBase;
    meta: NormalizeMetaBase;

    /**
     * Thunks
     */
    getItems: typeof getItemsThunk;
    getItem: typeof getItemThunk;
    updateItem: typeof updateItemThunk;
    deleteItem: typeof deleteItemThunk;
    createItem: typeof createItemThunk;
    setWebSocket: typeof setWebSocketThunk;

    /**
     * Actions
     */
    getItemsBegin: Action<INormalizeState, { subState: SubState }>;
    getItemsSuccess: Action<
        INormalizeState,
        {
            data: NormalizeItemBase;
            totalRecords: number;
            currentPage: number;
            totalPages: number;
            subState: SubState;
            orderedIds: ID[];
        }
    >;
    getItemsFailure: Action<
        INormalizeState,
        { error: Error; subState: SubState }
    >;
    getItemBegin: Action<INormalizeState, { subState: SubState }>;
    getItemSuccess: Action<
        INormalizeState,
        {
            data: NormalizeItemBase;
            subState: SubState;
        }
    >;
    getItemFailure: Action<
        INormalizeState,
        { error: Error; subState: SubState }
    >;
    updateItemBegin: Action<INormalizeState, { subState: SubState }>;
    updateItemSuccess: Action<
        INormalizeState,
        {
            data: NormalizeItemBase;
            subState: SubState;
        }
    >;
    updateItemFailure: Action<
        INormalizeState,
        { error: Error; subState: SubState }
    >;
    deleteItemBegin: Action<INormalizeState, { subState: SubState }>;
    deleteItemSuccess: Action<INormalizeState, { subState: SubState; id: ID }>;
    deleteItemFailure: Action<
        INormalizeState,
        { error: Error; subState: SubState }
    >;
    createItemBegin: Action<INormalizeState, { subState: SubState }>;
    createItemSuccess: Action<
        INormalizeState,
        {
            data: NormalizeItemBase;
            subState: SubState;
            addToActive?: boolean;
        }
    >;
    createItemFailure: Action<
        INormalizeState,
        { error: Error; subState: SubState }
    >;
    selectAllItems: Action<
        INormalizeState,
        {
            subState: SubState;
            selected: boolean;
        }
    >;
    selectItem: Action<
        INormalizeState,
        {
            subState: SubState;
            id: ID;
        }
    >;
    setFilters: Action<
        INormalizeState,
        {
            subState: SubState;
            filters: FilterData;
        }
    >;
    setWebSocketSuccess: Action<
        INormalizeState,
        {
            subState: SubState;
            enable: boolean;
        }
    >;
}

export const normalizeState: INormalizeState = {
    /**
     * State
     */
    items: generateItemState(),
    meta: generateMetaState(),

    /**
     * Thunks
     */
    getItems: getItemsThunk,
    getItem: getItemThunk,
    updateItem: updateItemThunk,
    deleteItem: deleteItemThunk,
    createItem: createItemThunk,
    setWebSocket: setWebSocketThunk,

    /**
     * Actions
     */
    getItemsBegin: action((state, payload) => {
        state.meta[payload.subState].get.done = false;
        state.meta[payload.subState].get.loading = true;
        state.meta[payload.subState].get.error = null;
    }),
    getItemsFailure: action((state, payload) => {
        state.meta[payload.subState].get.loading = false;
        state.meta[payload.subState].get.error = payload.error;
    }),
    getItemsSuccess: action((state, payload) => {
        state.items = updateItemState(payload.data, state.items);
        state.meta[payload.subState].get.loading = false;
        state.meta[payload.subState].get.done = true;
        state.meta[payload.subState].get.error = null;
        state.meta[payload.subState].activeIds = payload.orderedIds;
        state.meta[payload.subState].currentRecords =
            state.meta[payload.subState].activeIds.length;
        state.meta[payload.subState].totalRecords = payload.totalRecords;
        state.meta[payload.subState].totalPages = payload.totalPages;
        state.meta[payload.subState].currentPage = payload.currentPage;
    }),
    getItemBegin: action((state, payload) => {
        state.meta[payload.subState].getSingle.done = false;
        state.meta[payload.subState].getSingle.loading = true;
        state.meta[payload.subState].getSingle.error = null;
    }),
    getItemFailure: action((state, payload) => {
        state.meta[payload.subState].getSingle.loading = false;
        state.meta[payload.subState].getSingle.error = payload.error;
    }),
    getItemSuccess: action((state, payload) => {
        state.items = updateItemState(payload.data, state.items);
        state.meta[payload.subState].getSingle.loading = false;
        state.meta[payload.subState].getSingle.done = true;
        state.meta[payload.subState].getSingle.error = null;
    }),
    updateItemBegin: action((state, payload) => {
        state.meta[payload.subState].update.done = false;
        state.meta[payload.subState].update.loading = true;
        state.meta[payload.subState].update.error = null;
    }),
    updateItemFailure: action((state, payload) => {
        state.meta[payload.subState].update.loading = false;
        state.meta[payload.subState].update.error = payload.error;
    }),
    updateItemSuccess: action((state, payload) => {
        state.items = updateItemState(payload.data, state.items);
        state.meta[payload.subState].update.loading = false;
        state.meta[payload.subState].update.done = true;
        state.meta[payload.subState].update.error = null;
    }),
    deleteItemBegin: action((state, payload) => {
        state.meta[payload.subState].delete.done = false;
        state.meta[payload.subState].delete.loading = true;
        state.meta[payload.subState].delete.error = null;
    }),
    deleteItemFailure: action((state, payload) => {
        state.meta[payload.subState].delete.loading = false;
        state.meta[payload.subState].delete.error = payload.error;
    }),
    deleteItemSuccess: action((state, payload) => {
        state.items = removeItemState(
            payload.subState,
            payload.id.toString(),
            state.items
        );
        state.meta[payload.subState].delete.loading = false;
        state.meta[payload.subState].delete.done = true;
        state.meta[payload.subState].delete.error = null;
    }),
    createItemBegin: action((state, payload) => {
        state.meta[payload.subState].post.done = false;
        state.meta[payload.subState].post.loading = true;
        state.meta[payload.subState].post.error = null;
    }),
    createItemFailure: action((state, payload) => {
        state.meta[payload.subState].post.loading = false;
        state.meta[payload.subState].post.error = payload.error;
    }),
    createItemSuccess: action((state, payload) => {
        state.items = updateItemState(payload.data, state.items);
        if (payload.addToActive) {
            state.meta[payload.subState].activeIds = [
                ...state.meta[payload.subState].activeIds,
                Object.keys(payload.data[payload.subState])[0],
            ];
        }
        state.meta[payload.subState].post.loading = false;
        state.meta[payload.subState].post.done = true;
        state.meta[payload.subState].post.error = null;
    }),
    selectItem: action((state, payload) => {
        if (state.meta[payload.subState].selectedIds.includes(payload.id)) {
            state.meta[payload.subState].selectedIds = state.meta[
                payload.subState
            ].selectedIds.filter((id) => id !== payload.id);
        } else {
            state.meta[payload.subState].selectedIds = [
                ...state.meta[payload.subState].selectedIds,
                payload.id,
            ];
        }
    }),
    selectAllItems: action((state, payload) => {
        state.meta[payload.subState].selectedIds = payload.selected
            ? state.meta[payload.subState].activeIds
            : [];
    }),
    setFilters: action((state, payload) => {
        state.meta[payload.subState].filters = payload.filters;
    }),
    setWebSocketSuccess: action((state, payload) => {
        state.meta[payload.subState].webSocketEnabled = payload.enable;
    }),
};
