import * as React from "react";
import { Breadcrumb, Card, Layout, Row, Col } from "antd";
import { StyleSheet, css } from "aphrodite";

import "./BaseLayout.less";
import { getMatchedRoute, IRoute } from "../../../../shared/routes";
import { Link, useLocation } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons/lib";

interface IProps {
    className?: string;
    disableBreadcrump?: boolean;
    fluid?: boolean;
    actions?: React.ReactNode;
}

const cardBodyStyle: React.CSSProperties = {
    maxHeight: "calc(100vh - 185px)",
    overflowY: "auto",
};

const styles = StyleSheet.create({
    breadCrumb: {
        margin: "1.5em 0 1.5em 0",
    },
    content: {
        margin: "0em 4em 1.5em 4em",
    },
    card: {
        maxHeight: "calc(100vh - 185px)",
    },
    cardMax: {
        maxHeight: "calc(100vh - 185px)",
        maxWidth: "1600px",
        margin: "auto",
    },
    fillerNoBreadCrumbs: {
        height: "4em",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
    },
});

const renderBreadcrumbItems = (route: IRoute): React.ReactNode => {
    const title = route.title.toLowerCase();
    return (
        <>
            {route.parent && renderBreadcrumbItems(route.parent)}
            <Breadcrumb.Item>
                {route.path?.includes(":") ? (
                    title
                ) : (
                    <Link to={route.path || "/"}>{title}</Link>
                )}
            </Breadcrumb.Item>
        </>
    );
};

export const BaseLayout: React.FC<IProps> = (props) => {
    const location = useLocation();
    const route = getMatchedRoute(location);

    return (
        <Layout className={`base-layout ${props.className || ""}`}>
            <div className={css(styles.content)}>
                <Row align="middle">
                    <Col span={16}>
                        {!props.disableBreadcrump ? (
                            <Breadcrumb
                                className={css(styles.breadCrumb)}
                                separator=">"
                            >
                                <Breadcrumb.Item>
                                    <Link to="/">
                                        <HomeOutlined />
                                    </Link>
                                </Breadcrumb.Item>
                                {route && renderBreadcrumbItems(route)}
                            </Breadcrumb>
                        ) : (
                            <div className={css(styles.fillerNoBreadCrumbs)} />
                        )}
                    </Col>
                    <Col span={8} className={css(styles.actions)}>
                        {props.actions}
                    </Col>
                </Row>

                {!props.fluid ? (
                    <Card
                        className={`${css(styles.card)} ${css(
                            styles.cardMax
                        )} shadow-md`}
                        bodyStyle={cardBodyStyle}
                    >
                        {props.children}
                    </Card>
                ) : (
                    <Card
                        className={`${css(styles.card)} shadow-md`}
                        bodyStyle={cardBodyStyle}
                    >
                        {props.children}
                    </Card>
                )}
            </div>
        </Layout>
    );
};
