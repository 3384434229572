import * as React from "react";
import Tour, { ReactourStep } from "reactour";
import { t } from "../../../../translation";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const BaseTour: React.FC<IProps> = (props) => {
    const steps: ReactourStep[] = [
        {
            selector: ".form-week-picker",
            content: t("schedule.help.weekPicker"),
        },
        {
            selector: ".schedule-form",
            content: t("schedule.help.scheduleForm"),
        },
        {
            selector: ".schedule-status",
            content: t("schedule.help.scheduleStatus"),
        },
        {
            selector: ".form-select-0",
            content: t("schedule.help.employeeSelect"),
        },
        {
            selector: ".form-edit",
            content: t("schedule.help.editButton"),
        },
        {
            selector: ".form-send",
            content: t("schedule.help.sendButton"),
        },
    ];

    return (
        <Tour
            steps={steps}
            isOpen={props.isOpen}
            onRequestClose={() => props.onClose()}
            disableDotsNavigation={true}
            closeWithMask={false}
            disableInteraction={true}
            lastStepNextButton={t("schedule.help.finish")}
        />
    );
};
