export const navigationTranslationEn = {
    groups: "$t(groups.title)",
    logout: "Logout",
    notFound: {
        title: "Not found",
        subTitle: "Sorry, the page you visited does not exist.",
        backHome: "Back Home",
    },
    users: "$t(users.title)",
    schedule: "$t(schedule.title)",
    admin: "Admin",
    edit: "Edit",
    create: "Create",
    detail: "Detail",
    overview: "Overview",
};
