import { Action, action } from "easy-peasy";
import axios from "axios";
import { getCookie } from "../../../shared/functions/getCookie";
import { IAuthState } from "../state";

export const setTokenDataAction: Action<
    IAuthState,
    {
        token: string | null;
        remember: boolean;
        logout?: boolean;
        hijack?: boolean;
    }
> = action((state, payload) => {
    // Set hijackToken in localstorage for the hijack session
    if (payload.hijack && state.token) {
        localStorage.setItem("hijackToken", state.token);
    }

    state.token = payload.token;

    if (payload.token) {
        axios.defaults.headers.common = {
            Authorization: `Token ${payload.token}`,
            "Content-Type": "application/json",
            "X-Csrftoken": getCookie("csrftoken"),
        };

        if (payload.remember) {
            localStorage.setItem("token", payload.token);
        }
    } else {
        axios.defaults.headers.common = {
            Authorization: "",
            "X-Csrftoken": getCookie("csrftoken"),
        };
        localStorage.removeItem("token");
    }

    state.setTokenDone = true;
});
