import { getRoutes, IRoute } from "./sharedRoutes";
import {
    ConfirmPasswordResetScreen,
    PasswordResetScreen,
    LoginScreen,
    RegisterScreen,
} from "../../modules/auth/screens";
import { ID } from "../interfaces";

type UnAuthenticatedRoutes = "register" | "reset" | "reset-confirm" | "login";

export const getUnAuthenticatedRoutes = (): {
    [key: string]: IRoute<UnAuthenticatedRoutes>;
} => ({
    register: {
        component: RegisterScreen,
        exact: true,
        path: "register",
        key: "register",
        title: "users.auth.register.title",
    },

    resetPassword: {
        component: PasswordResetScreen,
        exact: true,
        path: "/reset",
        key: "reset",
        title: "users.auth.passwordReset.title",
        childs: [
            {
                component: ConfirmPasswordResetScreen,
                exact: true,
                path: "/confirm/:token",
                key: "reset-confirm",
                title: "users.auth.confirmPasswordReset.title",
            },
        ],
    },
    login: {
        component: LoginScreen,
        exact: false,
        path: undefined,
        key: "login",
        title: "users.auth.title",
    },
});

export const getUnAuthenticatedPath = (
    key: UnAuthenticatedRoutes,
    params?: { [key: string]: ID }
): string => {
    const routes = getRoutes(getUnAuthenticatedRoutes());
    if (key in routes && routes[key].path) {
        let route = routes[key].path;

        if (!route) {
            return "/";
        }

        if (params) {
            for (const [_key, value] of Object.entries(params)) {
                route = route.replace(`:${_key}`, value.toString());
            }
        }
        return route;
    }
    return "/";
};
