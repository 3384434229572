import * as React from "react";
import { Form, Select, SelectProps } from "formik-antd";
// tslint:disable-next-line:no-submodule-imports
import { FormItemProps } from "formik-antd/src/form-item/index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";
import { FormContext } from "../index";

interface IProps {
    label: string;
    name: string;
    itemProps?: Omit<FormItemProps, "children" | "name">;
    withFilter?: boolean;
}

export const FormSelectItem: React.FC<IProps & SelectProps> = ({
    withFilter,
    ...props
}) => {
    const formContext = React.useContext(FormContext);

    const filterOption = (input: any, option: any) => {
        if (!option) {
            return false;
        }

        if (Array.isArray(option.children)) {
            const value = option.children.join();
            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        if (option && option.label) {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        return (
            option &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    return (
        <Form.Item
            name={props.name}
            label={props.label}
            validateStatus="success"
            {...props.itemProps}
        >
            <Select
                disabled={formContext.readOnly}
                filterOption={withFilter ? filterOption : undefined}
                showSearch={!!withFilter}
                {...props}
                name={props.name}
                data-cy={`input-${toSnake(props.name).replace("_", "-")}`}
            >
                {props.children}
            </Select>
        </Form.Item>
    );
};
