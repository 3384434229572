export const buttonsTranslationNl = {
    create: "Aanmaken",
    update: "Opslaan",
    delete: "Verwijder",
    edit: "Aanpassen",
    reset: "Reset",
    request: "Aanvragen",
    view: "Bekijk",
    send: "Versturen",
    close: "Sluiten",
};
