export const usersTranslationEn = {
    title: "Users",
    tableTitle: "Manage users",
    fields: {
        username: "Username",
        password: "Password",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        lastLogin: "Last login",
        groups: "Groups",
    },
    form: {
        add: "Add user",
        edit: "Edit user: {{username}}",
    },
    auth: {
        title: "Login",
        login: {
            success: "Successfully logged in!",
            wrongCredentials: "Credentials are not correct!",
        },
        logout: {
            success: "Successfully logged out in!",
            error: "Something went wrong while logging out!",
        },
        passwordReset: {
            title: "Forgot password",
        },
        confirmPasswordReset: {
            title: "Change password",
            notFound: "Link is not correct, please create a new link!",
            success: "Successfully changed password!",
        },
        rememberMe: "Remember me",
    },
};
