import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/baseFormik";
import { WeekStatus, weekStatuses } from "../models";

export interface IScheduleForm {
    status: WeekStatus;
    start: string;
    end: string;
    dates: {
        date: string;
        employeeIds: string[];
    }[];
}

export const getScheduleValidationSchema = () =>
    getTypedSchema<IScheduleForm>({
        dates: Yup.array().of(
            Yup.object().shape({
                date: Yup.string().required(),
                employeeIds: Yup.array().of(Yup.string()),
            })
        ),
        status: Yup.mixed().oneOf([...weekStatuses]),
        start: Yup.string(),
        end: Yup.string(),
    });
