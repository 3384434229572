import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { logger } from "../../../shared/logging";
import { Tenant } from "../models";
import { changeLanguage } from "../../../translation";

export const getCurrentTenantThunk: Thunk<
    IAuthState,
    void,
    any,
    RootState,
    Promise<Tenant>
> = thunk((actions) => {
    actions.getCurrentTenantBegin();

    return HttpClient.get<Tenant>(`/api/tenants/current/`, {
        obj: Tenant,
    })
        .then((data) => {
            // Change language based on setting
            changeLanguage(data.language).then(() => {
                actions.getCurrentTenantSuccess(data);
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            actions.getCurrentTenantFailure(error);
            throw error;
        });
});
