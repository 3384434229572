export const httpTranslationNl = {
    crud: {
        create: {
            success: "Het item is succesvol aangemaakt!",
            error: "Er ging iets fout bij het aanmaken van het item!",
            websocket: {
                title: "Er is nieuwe data!",
                click: "Klik ",
                here: "hier ",
                toRefresh: "om te verversen!",
            },
        },
        read: {
            success: "De item(s) zijn succesvol opgehaald!",
            error: "Er ging iets fout bij het ophalen van de item(s)!",
        },
        update: {
            success: "Het item is succesvol aangepast!",
            error: "Er ging iets fout bij het aanpassen van het item.",
        },
        delete: {
            success: "Het item is succesvol verwijderd!",
            error: "Er ging iets fout bij het verwijderen van het item!",
        },
    },
};
