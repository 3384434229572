export const scheduleTranslationEn = {
    title: "Schedule",
    status: {
        open: {
            title: "Not yet sent",
            description: "The schedule for this week hasn't been send yet.",
        },
        pending: {
            title: "Waiting for reaction",
            description: "The schedule has been send and waiting for reaction.",
        },
        accepted: {
            title: "Accepted",
            description: "The schedule is accepted.",
        },
        rejected: {
            title: "Rejected",
            description: "The schedule is rejected.",
        },
        blocked: {
            title: "Blocked",
            description:
                "Schedules can only be sent till thursday for the next week. " +
                "Wait till monday to create the new schedule.",
        },
    },
    confirm: {
        text:
            "Are you sure you want to send the schedule? Once it has been sent, it cannot be changed anymore.",
    },
    help: {
        title: "Help?",
        weekPicker:
            "Here you can select a week for which you want to make the schedule!",
        scheduleForm:
            "Dit is de complete planning voor de geselecteerde week. Hier kunt u medewerkers gaan plannen voor deze week.",
        scheduleStatus:
            "This is the complete schedule for the selected week. Here you can start planning employees for this week.",
        employeeSelect:
            "Here you can select an employee to schedule for the above date!",
        editButton:
            "Here you can save the schedule for the selected week. " +
            "This action will only save the schedule not send it. " +
            "This so that you can adjust the planning and complete or send it another day.",
        sendButton:
            "Here you can send a schedule. This sends the current schedule. " +
            "Once the schedule has been sent, it cannot be modified.",
        finish: "Start planning!",
    },
};
