export const navigationTranslationNl = {
    groups: "$t(groups.title)",
    logout: "Uitloggen",
    notFound: {
        title: "Niet gevonden",
        subTitle: "Deze pagina bestaat niet.",
        backHome: "Terug",
    },
    users: "$t(users.title)",
    schedule: "$t(schedule.title)",
    admin: "Beheer",
    edit: "Aanpassen",
    create: "Aanmaken",
    detail: "Bekijken",
    overview: "Overzicht",
};
