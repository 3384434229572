export const httpTranslationEn = {
    crud: {
        create: {
            success: "Successfully created the item!",
            error: "Something went wrong while creating the item!",
            websocket: {
                title: "There is new data!",
                click: "Click ",
                here: "here ",
                toRefresh: "to refresh!",
            },
        },
        read: {
            success: "Successfully retrieved the data!",
            error: "Something went wrong while retrieving the data!",
        },
        update: {
            success: "Successfully updated the item!",
            error: "Something went wrong while updating the item!",
        },
        delete: {
            success: "Successfully deleted!",
            error: "Something went wrong while deleting the item!",
        },
    },
};
