import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { Notifier } from "../../../shared/functions";

interface IInputData {
    email: string;
}

export const passwordResetThunk: Thunk<
    IAuthState,
    IInputData,
    any,
    RootState,
    Promise<void>
> = thunk(async (actions, payload) => {
    actions.passwordResetBegin();

    return HttpClient.post<{}>(`/api/auth/reset/`, {
        email: payload.email,
    })
        .then(() => {
            actions.passwordResetSuccess();
            Notifier.success("http.crud.create.success");
        })
        .catch((error) => {
            actions.passwordResetFailure(error);
            Notifier.error("http.crud.create.error");
            throw error;
        });
});
