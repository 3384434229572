import * as React from "react";
import { Spin } from "antd";

export const BaseSpinner: React.FC = () => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
        }}
    >
        <Spin />
    </div>
);
