import { normalize } from "normalizr";
import { NormalizeItemBase } from "../normalizer";

export const getNormalizedData = (
    data: any,
    schema: any
): NormalizeItemBase => {
    // @ts-ignore
    return normalize(data, schema).entities;
};
