import { Schema } from "normalizr";
import { ID } from "../interfaces";

export const subStates = ["users", "groups", "employees"] as const;

export type SubState = typeof subStates[number];

export type NormalizeItemBase = {
    [key in SubState]: { [key: string]: object };
};

export type NormalizeMetaBase = {
    [key in SubState]: INormalizedMeta;
};

export interface INormalizedDefinition {
    model: any;
    schema: Schema;
    baseUrl: string;
}

export type FilterData = { [key: string]: string | string[] };

export interface INormalizedMetaMethod {
    loading: boolean;
    done: boolean;
    error: Error | null;
}

export interface INormalizedMeta {
    getSingle: INormalizedMetaMethod;
    get: INormalizedMetaMethod;
    update: INormalizedMetaMethod;
    post: INormalizedMetaMethod;
    delete: INormalizedMetaMethod;
    currentRecords: number;
    totalRecords: number;
    totalPages: number;
    currentPage: number;
    activeIds: ID[];
    selectedIds: ID[];
    filters: FilterData;
    webSocketEnabled: boolean;
}
