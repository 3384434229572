// tslint:disable-next-line:no-submodule-imports
import { FormikErrors, FormikHelpers } from "formik/dist/types";
import * as Yup from "yup";

type Errors = { [key: string]: string[] | Errors };

const getErrors = (errors: Errors) => {
    const formattedErrors: Errors = {};
    Object.keys(errors).forEach((key) => {
        const error = errors[key];

        // Check if its an array
        if (!Array.isArray(error)) {
            formattedErrors[key] = getErrors(error);
            return;
        }

        formattedErrors[key] = error.map((err) =>
            typeof err === "string" ? err : "Unknown validation error"
        );
    });
    return formattedErrors;
};

export const formatBackendValidationErrors = <T>(
    errors: any
): FormikErrors<T> => {
    return getErrors(errors) as FormikErrors<T>;
};

export const setBackendErrors = (helper: FormikHelpers<any>, error: any) => {
    if (error.response && error.response.status === 400) {
        const validationErrors = formatBackendValidationErrors(
            error.response.data
        );
        helper.setErrors(validationErrors);
    }
    helper.setSubmitting(false);
};

type SchemaType<T extends object> = {
    [key in keyof T]: Yup.Schema<T[key]>;
};

export const getTypedSchema = <T extends object>(
    schema: SchemaType<T>
): Yup.ObjectSchema<T> => Yup.object(schema);
