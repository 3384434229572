import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { TranslationEn, TranslationNl } from "./languages";
import * as Yup from "yup";

const resources = {
    en: {
        translation: TranslationEn,
    },
    nl: {
        translation: TranslationNl,
    },
};

export const initializeI18 = () => {
    const options = {
        fallbackLng: "en",
        whitelist: ["en", "nl"],
        resources,
        interpolation: {
            // Not necessary for React, since it doesn't allow parsing HTML in strings.
            escapeValue: false,
        },
    };

    i18next.use(initReactI18next).init(options);
};

export const t = i18next.t.bind(i18next);

export const changeLanguage = async (language: "en" | "EN" | "nl" | "NL") => {
    await i18next.changeLanguage(language.toLowerCase());
    setupYupLocale();
};

export const setupYupLocale = () => {
    const localeObject =
        resources[i18next.language as "en" | "nl"].translation.forms.validation
            .yup;
    Yup.setLocale(localeObject);
};
