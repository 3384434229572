import * as React from "react";
import { Modal } from "antd";
import { t } from "../../../../translation";

interface IProps {
    onClose: (accepted: boolean) => void;
}

export const ScheduleConfirmModal: React.FC<IProps> = (props) => (
    <Modal
        title={t("buttons.send")}
        visible={true}
        onCancel={() => props.onClose(false)}
        onOk={() => props.onClose(true)}
    >
        {t("schedule.confirm.text")}
    </Modal>
);
