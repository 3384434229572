import * as React from "react";
import { FieldArray, Formik, FormikProps } from "formik";
import {
    getScheduleValidationSchema,
    IScheduleForm,
} from "../../forms/scheduleInitialValues";
import { useNormalizedState } from "../../../base/hooks";
import { Employee } from "../../../employee/models";
import { Form, SubmitButton } from "formik-antd";
import moment, { Moment } from "moment";
import {
    API_DATE_FORMAT,
    DATE_FORMAT,
    Notifier,
} from "../../../../shared/functions";
import { updateItemInArray } from "../../../../shared/functions/updateItemInArray";
import { EmployeeCard } from "./EmployeeCard";
import { FormSelectItem } from "../../../base/components/BaseForm";
import { t } from "../../../../translation";
import { Alert, Button } from "antd";
import { HttpClient } from "../../../../shared/networking";
import * as qs from "qs";
import { BaseSpinner } from "../../../base/components/BaseSpinner";
import { Schedule, WeekStatus } from "../../models";
import { ScheduleConfirmModal } from "./ScheduleConfirmModal";

interface IProps {
    date: Moment;
}

export const ScheduleForm: React.FC<IProps> = (props) => {
    const [
        initialValues,
        setInitialValues,
    ] = React.useState<null | IScheduleForm>(null);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);

    const employees = useNormalizedState<Employee>("employees");

    const nextMonday = props.date.clone().startOf("isoWeek");
    // All the dates for next week.
    const dates = Array(7)
        .fill(0)
        .map((_, index) => nextMonday.clone().add(index, "days"));
    const monday = dates[0];
    const sunday = dates[dates.length - 1];

    React.useEffect(() => {
        const params = {
            start: monday.clone().format(API_DATE_FORMAT),
            end: sunday.clone().format(API_DATE_FORMAT),
        };
        setInitialValues(null);
        HttpClient.get<Schedule>(
            `/api/schedule/${qs.stringify(params, { addQueryPrefix: true })}`
        ).then((res) => {
            setInitialValues(res);
        });
    }, [props.date]);

    if (moment() > monday.clone().subtract(4, "days")) {
        return (
            <Alert
                style={{ marginTop: "1em" }}
                message={t("schedule.status.blocked.title")}
                description={t("schedule.status.blocked.description")}
                type="error"
            />
        );
    }

    const addEmployeeOnDate = (
        helpers: FormikProps<IScheduleForm>,
        date: Moment,
        employeeId: string
    ) => {
        const dateKey = date.format(API_DATE_FORMAT);

        helpers.setValues({
            ...helpers.values,
            dates: updateItemInArray(
                helpers.values.dates,
                dateKey,
                (item) => {
                    if (item.employeeIds.includes(employeeId)) {
                        return {
                            ...item,
                            employeeIds: item.employeeIds.filter(
                                (emp) => emp !== employeeId
                            ),
                        };
                    }

                    return {
                        ...item,
                        employeeIds: [...item.employeeIds, employeeId],
                    };
                },
                "date"
            ),
        });
    };

    const getTagColorForStatus = (
        status: WeekStatus
    ): "success" | "info" | "warning" | "error" => {
        switch (status) {
            case "open":
                return "info";
            case "pending":
                return "warning";
            case "accepted":
                return "success";
            case "rejected":
                return "error";
        }
    };

    const onConfirm = (
        helpers: FormikProps<IScheduleForm>,
        accepted: boolean
    ) => {
        if (accepted) {
            helpers.setFieldValue("status", "pending");
            helpers.submitForm().then(() => {
                setShowConfirmModal(false);
            });
            return;
        }

        setShowConfirmModal(false);
    };

    if (!initialValues) {
        return <BaseSpinner />;
    }

    return (
        <>
            <Formik<IScheduleForm>
                initialValues={initialValues}
                onSubmit={(values, helpers) => {
                    const cleanedValues = {
                        ...values,
                        dates: values.dates.map((dateInfo) => ({
                            date: dateInfo.date,
                            employeeIds: dateInfo.employeeIds,
                        })),
                        start: dates[0].format(API_DATE_FORMAT),
                        end: dates[dates.length - 1].format(API_DATE_FORMAT),
                    };

                    const oldInitialValues = initialValues;
                    setInitialValues(null);

                    HttpClient.post<Schedule>("/api/schedule/", cleanedValues)
                        .then((res) => {
                            helpers.setSubmitting(false);
                            setTimeout(() => setInitialValues(res), 1000);
                            Notifier.success(t("http.crud.update.success"));
                        })
                        .catch(() => {
                            helpers.setSubmitting(false);
                            setTimeout(
                                () => setInitialValues(oldInitialValues),
                                1000
                            );
                            Notifier.error(t("http.crud.update.error"));
                        });
                }}
                validationSchema={getScheduleValidationSchema()}
            >
                {(helpers) => {
                    const blockForm = helpers.values.status !== "open";
                    return (
                        <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="schedule-form"
                        >
                            {showConfirmModal && (
                                <ScheduleConfirmModal
                                    onClose={(accepted) =>
                                        onConfirm(helpers, accepted)
                                    }
                                />
                            )}
                            <div
                                style={{ margin: "1em 0", width: "100%" }}
                                className="schedule-status"
                            >
                                <Alert
                                    message={t(
                                        `schedule.status.${helpers.values.status}.title`
                                    )}
                                    description={t(
                                        `schedule.status.${helpers.values.status}.description`
                                    )}
                                    type={getTagColorForStatus(
                                        helpers.values.status
                                    )}
                                    closable={false}
                                />
                            </div>
                            <table
                                className="table-bordered"
                                style={{ maxHeight: "800px" }}
                            >
                                <thead>
                                    <tr>
                                        {dates.map((date, index) => (
                                            <th key={index}>
                                                {date.format(DATE_FORMAT)}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {dates.map((date, index) => {
                                            const dateString = date.format(
                                                API_DATE_FORMAT
                                            );
                                            const dateInfo = helpers.values.dates.find(
                                                (dInfo) =>
                                                    dInfo.date === dateString
                                            );

                                            if (!dateInfo) {
                                                return null;
                                            }

                                            const filteredEmployees = employees.filter(
                                                (emp) => {
                                                    // Don't show if its inactive.
                                                    if (!emp.isActive) {
                                                        return false;
                                                    }

                                                    // Don't show if its already planned.
                                                    if (
                                                        dateInfo.employeeIds.includes(
                                                            emp.id
                                                        )
                                                    ) {
                                                        return false;
                                                    }

                                                    // Don't show if its not available.
                                                    if (
                                                        emp.notAvailableDates.includes(
                                                            dateString
                                                        )
                                                    ) {
                                                        return false;
                                                    }
                                                    return true;
                                                }
                                            );

                                            return (
                                                <td key={index} valign="top">
                                                    {!blockForm && (
                                                        <FormSelectItem
                                                            label={t(
                                                                "forms.add"
                                                            )}
                                                            itemProps={{
                                                                className: `form-select-${index}`,
                                                                validateStatus: undefined,
                                                            }}
                                                            name=" "
                                                            withFilter={true}
                                                            value={null}
                                                            options={filteredEmployees.map(
                                                                (employee) => ({
                                                                    label:
                                                                        employee.name,
                                                                    value:
                                                                        employee.id,
                                                                })
                                                            )}
                                                            onSelect={(
                                                                employeeId
                                                            ) =>
                                                                addEmployeeOnDate(
                                                                    helpers,
                                                                    date,
                                                                    employeeId
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    <FieldArray
                                                        name={`dates.${index}.employeeIds`}
                                                        render={(
                                                            arrayHelpers
                                                        ) => {
                                                            const foundEmployees: Employee[] = [];

                                                            dateInfo.employeeIds.forEach(
                                                                (
                                                                    employeeId
                                                                ) => {
                                                                    const employee = employees.find(
                                                                        (emp) =>
                                                                            emp.id ===
                                                                            employeeId
                                                                    );

                                                                    if (
                                                                        employee
                                                                    ) {
                                                                        foundEmployees.push(
                                                                            employee
                                                                        );
                                                                    }
                                                                }
                                                            );

                                                            return (
                                                                <>
                                                                    {foundEmployees.map(
                                                                        (
                                                                            employee,
                                                                            employeeIndex
                                                                        ) => (
                                                                            <EmployeeCard
                                                                                key={
                                                                                    employeeIndex
                                                                                }
                                                                                employee={
                                                                                    employee
                                                                                }
                                                                                onDelete={() =>
                                                                                    !blockForm &&
                                                                                    arrayHelpers.remove(
                                                                                        employeeIndex
                                                                                    )
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                            {helpers.values.status === "open" && (
                                <div
                                    style={{
                                        marginTop: "1em",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            setShowConfirmModal(true)
                                        }
                                        className="form-send"
                                    >
                                        {t("buttons.send")}
                                    </Button>
                                    <SubmitButton
                                        type="default"
                                        className="form-edit"
                                    >
                                        {t("buttons.edit")}
                                    </SubmitButton>
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
