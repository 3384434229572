import { LocaleObject } from "yup";

/* eslint-disable */

// tslint:disable:no-invalid-template-strings
const yupValidation: LocaleObject = {
    mixed: {
        default: "This field is invalid!",
        required: "This field is required!",
        oneOf: "This field must be one of the following values: ${values}!",
        notOneOf:
            "This field must not be one of the following values: ${values}!",
        notType: "This field should not be of the following type: ${type}!",
    },
    number: {
        min: "This field must be greater than or equal to ${min}!",
        max: "This field must be less than or equal to ${max}!",
        lessThan: "This field must be less than ${less}!",
        moreThan: "This field must be greater than ${more}!",
        positive: "This field must be a positive number!",
        negative: "This field must be a negative number!",
        integer: "This field must be an integer!",
    },
    string: {
        length: "This field must be exactly ${length} characters!",
        min: "This field must be at least ${min} characters!",
        max: "This field must be at most ${max} characters!",
        matches: 'This field must match the following: "${regex}"',
        email: "This field must be a valid email!",
        url: "This field must be a valid URL!",
        trim: "This field must be a trimmed string!",
        lowercase: "This field must be a lowercase string!",
        uppercase: "This field must be a upper case string!",
    },
    date: {
        min: "This field must be later than ${min}!",
        max: "This field must be at earlier than ${max}!",
    },
    array: {
        min: "This field must have at least ${min} items!",
        max: "This field must have less than or equal to ${max} items!",
    },
    boolean: {},
    object: {
        noUnknown: "Dit veld heeft niet gespecificeerde velden: ${unknown}!",
    },
};

export const formsTranslationEn = {
    empty: "Empty",
    validation: {
        yup: yupValidation,
    },
    yes: "Yes",
    no: "No",
    unknown: "Unknown",
    add: "Add",
    edit: "Edit",
};
