import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { INormalizeState } from "../state";
import { logger } from "../../../shared/logging";
import {
    getDefinitionForSubState,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { cleanFilters, isEmpty } from "../../../shared/functions/objectHelpers";
import { Notifier } from "../../../shared/functions";

interface IPayload {
    subState: SubState;
}

interface IOutput {
    data: NormalizeItemBase;
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    orderedIds: number[];
}

/**
 * Generic function to get the items from the normalized state
 */
export const getItemsThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<IOutput>
> = thunk((actions, payload, { getState }) => {
    actions.getItemsBegin({ subState: payload.subState });
    const definition = getDefinitionForSubState(payload.subState);

    const filters = getState().meta[payload.subState].filters;

    const extra = isEmpty(filters) ? "" : cleanFilters(filters);

    return HttpClient.get<IOutput>(`/api/${definition.baseUrl}/${extra}`, {
        schema: [definition.schema],
    })
        .then((data: IOutput) => {
            actions.getItemsSuccess({
                data: data.data,
                currentPage: data.currentPage,
                totalPages: data.totalPages,
                totalRecords: data.totalRecords,
                subState: payload.subState,
                orderedIds: data.orderedIds,
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            Notifier.error("http.crud.read.error");
            actions.getItemsFailure({ error, subState: payload.subState });
            throw error;
        });
});
