import { matchPath, RouteProps } from "react-router-dom";
import { getAuthenticatedRoutes } from "./authenticatedRoutes";

export interface IRoute<T = string> extends RouteProps {
    key: T;
    path: string | undefined;
    title: string;
    childs?: IRoute<T>[];
    parent?: IRoute<T>;
}

export const getMatchedRoute = (locationParam: any): IRoute | undefined => {
    return Object.values(
        getRoutes(getAuthenticatedRoutes())
    ).find((routeFind) => matchPath(locationParam.pathname, routeFind));
};

export const getMatchedKeys = (locationParam: any): string[] => {
    const routes = Object.values(
        getRoutes(getAuthenticatedRoutes())
    ).filter((routeFind) => matchPath(locationParam.pathname, routeFind));

    const matchedKeys: string[] = [];

    routes.forEach((route) => {
        if (route && route.path && !Array.isArray(route.path)) {
            matchedKeys.push(route.path);
        }
    });

    return matchedKeys;
};

const setRoutesForChilds = (
    parent: IRoute,
    newRoutes: { [key: string]: IRoute }
) => {
    if (parent.childs && parent.childs.length) {
        parent.childs.forEach((child) => {
            setRoutesForChilds(child, newRoutes);

            newRoutes[child.key] = {
                ...child,
                key: `${parent.key}${child.key}`,
                path: `${parent.path}${child.path}`,
                parent,
            };
        });
    }

    newRoutes[parent.key] = parent;
};

export const getRoutes = (routes: { [key: string]: IRoute }) => {
    const newRoutes: { [key: string]: IRoute } = {};
    for (const route of Object.values(routes)) {
        setRoutesForChilds(route, newRoutes);
    }

    return newRoutes;
};
