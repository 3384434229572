import { ID } from "../interfaces";

export const updateItemInArray = <T extends any>(
    array: T[],
    itemId: ID,
    updateItemCallback: (item: T) => T,
    field: keyof T
) => {
    return array.map((item) => {
        if (item[field] !== itemId) {
            // Since we only want to update one item, preserve all others as they are now
            return item;
        }
        // Use the provided callback to create an updated item
        return updateItemCallback(item);
    });
};
