import { Thunk, thunk } from "easy-peasy";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { Notifier } from "../../../shared/functions";
import { HttpClient } from "../../../shared/networking";

export const logoutThunk: Thunk<
    IAuthState,
    void,
    any,
    RootState,
    Promise<boolean>
> = thunk((actions) => {
    return HttpClient.post<{}>(`/api/auth/logout/`, {})
        .then(() => {
            actions.setTokenData({
                token: null,
                remember: false,
                logout: true,
            });
            actions.clearCurrentUser();
            Notifier.success("users.auth.logout.success");
            return true;
        })
        .catch(() => {
            Notifier.error("users.auth.logout.error");
            return false;
        });
});
