import * as React from "react";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import {
    getDefinitionForSubState,
    SubState,
    subStates,
} from "../../../../shared/normalizer";
import { logger } from "../../../../shared/logging";
import { Notifier } from "../../../../shared/functions";
import { getNormalizedData, WsManager } from "../../../../shared/networking";
import { Button } from "antd";
import { keysToCamel } from "../../../../shared/functions/caseCovertion";
import { t } from "../../../../translation";

export const WsController: React.FC = () => {
    const {
        getItems,
        createItemSuccess,
        updateItemSuccess,
        deleteItemSuccess,
    } = useStoreActions((actions) => actions.normalize);

    const currentUser = useStoreState((state) => state.auth.currentUser);

    // Get webSockets from state
    const webSockets = useStoreState((state) => {
        const sockets: {
            [key: string]: boolean;
        } = {};

        subStates.forEach((subState) => {
            sockets[subState] = state.normalize.meta[subState].webSocketEnabled;
        });

        return sockets as {
            [key in SubState]: boolean;
        };
    });

    // Not using the set function since we don't need it
    const [wsManager] = React.useState<WsManager>(new WsManager());

    const onMessage = (event: MessageEvent, room: SubState) => {
        const parsedJson = JSON.parse(event.data);
        const type = parsedJson.type;
        const model = parsedJson.model;
        const camelModel = keysToCamel(model);
        const createdById = parsedJson.created_by;

        if (currentUser && currentUser.id === createdById) {
            logger.info("Event triggered by the same user.");
            return;
        }

        const definition = getDefinitionForSubState(room);

        switch (type) {
            case "create":
                Notifier.success("http.crud.create.websocket.title", {
                    key: "WebsocketUpdate",
                    description: (
                        <>
                            {t("http.crud.create.websocket.click")}
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                onClick={() => {
                                    getItems({ subState: room });
                                    Notifier.close("WebsocketUpdate");
                                }}
                            >
                                {t("http.crud.create.websocket.here")}
                            </Button>
                            {t("http.crud.create.websocket.toRefresh")}
                        </>
                    ),
                });
                const normalizedDataCreate = getNormalizedData(
                    camelModel,
                    definition.schema
                );
                createItemSuccess({
                    subState: room,
                    data: normalizedDataCreate,
                });
                break;
            case "update":
                const normalizedDataUpdate = getNormalizedData(
                    camelModel,
                    definition.schema
                );

                updateItemSuccess({
                    subState: room,
                    data: normalizedDataUpdate,
                });
                break;
            case "delete":
                deleteItemSuccess({
                    subState: room,
                    id: model.id,
                });
                break;
        }
    };

    React.useEffect(() => {
        wsManager.checkEnabledWebSockets(webSockets, onMessage);
    }, [wsManager, webSockets]);

    return null;
};
