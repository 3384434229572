/**
 * Returns the keys that are changed when comparing the new object to the
 * old object.
 * @param oldObject original object.
 * @param newObject object with changed data.
 */
import { isEmpty } from "./objectHelpers";

export const getDifference = (
    oldObject: { [key: string]: any },
    newObject: { [key: string]: any }
): object =>
    Object.keys(newObject).reduce((diff, key) => {
        if (oldObject[key] === newObject[key]) {
            return diff;
        }

        if (
            typeof oldObject[key] === "object" &&
            typeof newObject[key] === "object"
        ) {
            if (Array.isArray(oldObject[key])) {
                if (
                    Array.isArray(newObject[key]) &&
                    oldObject[key].length === newObject[key].length &&
                    oldObject[key].every(
                        (value: any, index: number) =>
                            value === newObject[key][index]
                    )
                ) {
                    return diff;
                }
                return {
                    ...diff,
                    [key]: newObject[key],
                };
            }

            const dictDiff = getDifference(oldObject[key], newObject[key]);

            if (isEmpty(dictDiff)) {
                return diff;
            }

            return {
                ...diff,
                [key]: dictDiff,
            };
        }

        return {
            ...diff,
            [key]: newObject[key],
        };
    }, {});
