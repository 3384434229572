import { message as AntDMessage, notification } from "antd";
import { t } from "../../translation";
// tslint:disable-next-line:no-submodule-imports
import { ArgsProps } from "antd/lib/notification";

const defaultSuccessDuration = 1.5;
const defaultWarningDuration = 2;
const defaultErrorDuration = 3;

type NotifierOptions = Omit<ArgsProps, "message">;

const success = (message: string, options?: NotifierOptions) => {
    const translatedMessage = t(message);

    if (options && options.description) {
        notification.success({
            ...options,
            message: translatedMessage,
            duration: options.duration || defaultSuccessDuration,
        });
        return;
    }

    const duration =
        options && options.duration ? options.duration : defaultSuccessDuration;

    AntDMessage.success(translatedMessage, duration);
};

const warning = (message: string, options?: NotifierOptions) => {
    const translatedMessage = t(message);

    if (options && options.description) {
        notification.warning({
            ...options,
            message: translatedMessage,
            duration: options.duration || defaultWarningDuration,
        });
        return;
    }

    const duration =
        options && options.duration ? options.duration : defaultWarningDuration;

    AntDMessage.warning(translatedMessage, duration);
};

const error = (message: string, options?: NotifierOptions) => {
    const translatedMessage = t(message);

    if (options && options.description) {
        notification.error({
            ...options,
            message: translatedMessage,
            duration: options.duration || defaultErrorDuration,
        });
        return;
    }

    const duration =
        options && options.duration ? options.duration : defaultErrorDuration;

    AntDMessage.error(translatedMessage, duration);
};

const close = (key: string) => notification.close(key);

export const Notifier = {
    success,
    warning,
    error,
    close,
};
