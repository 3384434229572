import * as React from "react";
import { Typography } from "antd";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { t } from "../../../translation";
import {
    InitialDataPasswordResetForm,
    PasswordResetForm,
} from "../components/PasswordReset";
import { useHistory } from "react-router-dom";
import { BaseUnauthenticatedLayout } from "../../base/components/BaseUnauthenticatedLayout";

export const PasswordResetScreen: React.FC = () => {
    const history = useHistory();
    const isLoading = useStoreState((state) => state.auth.loginLoading);

    const passwordResetAction = useStoreActions(
        (actions) => actions.auth.passwordReset
    );

    const onSubmit = (values: InitialDataPasswordResetForm) =>
        passwordResetAction(values).then(() => history.push("/"));

    return (
        <BaseUnauthenticatedLayout>
            <Typography.Title>
                {t("users.auth.passwordReset.title")}
            </Typography.Title>
            <PasswordResetForm onSubmit={onSubmit} isLoading={isLoading} />
        </BaseUnauthenticatedLayout>
    );
};
