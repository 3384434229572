import * as React from "react";
import { t } from "../../../../translation";
import { Button, Result } from "antd";

interface IProps {
    onClick?: () => void;
}

export const BaseNotFound: React.FC<IProps> = (props) => (
    <Result
        status="404"
        title="404"
        subTitle={t("navigation.notFound.subTitle")}
        extra={
            props.onClick ? (
                <Button
                    type="primary"
                    onClick={() => props.onClick && props.onClick()}
                >
                    {t("navigation.notFound.backHome")}
                </Button>
            ) : (
                undefined
            )
        }
    />
);
