export const usersTranslationNL = {
    title: "Gebruikers",
    tableTitle: "Beheer gebruikers",
    fields: {
        username: "Gebruikersnaam",
        password: "Wachtwoord",
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "Emailadres",
        lastLogin: "Laatste login",
        groups: "Groepen",
    },
    form: {
        add: "Voeg gebruiker toe",
        edit: "Pas gebruiker aan: {{username}}",
    },
    auth: {
        title: "Login",
        login: {
            success: "Login succesvol!",
            wrongCredentials: "Gegevens zijn niet correct!",
        },
        logout: {
            success: "Successvol uitgelogd!",
            error: "Er ging iets fout bij het uitloggen!",
        },
        passwordReset: {
            title: "Wachtwoord vergeten",
        },
        confirmPasswordReset: {
            title: "Wachtwoord aanpassen",
            notFound: "Link is niet valide, vraag een nieuwe reset link aan!",
            success: "Succesvol aangepast!",
        },
        rememberMe: "Onthouden",
    },
};
