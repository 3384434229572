import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { logger } from "../../../shared/logging";
import { User } from "../models";
import { changeLanguage } from "../../../translation";

export const getCurrentUserThunk: Thunk<
    IAuthState,
    void,
    any,
    RootState,
    Promise<User>
> = thunk((actions) => {
    actions.getCurrentUserBegin();

    return HttpClient.get<User>(`/api/users/current/`, {
        obj: User,
    })
        .then((data) => {
            // Change language based on setting
            changeLanguage(data.language).then(() => {
                actions.getCurrentUserSuccess(data);
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            actions.getCurrentUserFailure(error);

            // Throw session away on 401
            if (error.response && error.response.status === 401) {
                actions.setTokenData({
                    token: null,
                    remember: false,
                });
            }
            throw error;
        });
});
