import * as React from "react";
import { Typography } from "antd";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { t } from "../../../translation";
import {
    ConfirmPasswordResetForm,
    InitialDataConfirmPasswordResetForm,
} from "../components/ConfirmPasswordReset";
import { useHistory } from "react-router-dom";
import { setBackendErrors } from "../../base/helpers/baseFormik";
import { BaseUnauthenticatedLayout } from "../../base/components/BaseUnauthenticatedLayout";

interface IProps {
    match: { params: { token: string } };
}

export const ConfirmPasswordResetScreen: React.FC<IProps> = (props) => {
    const history = useHistory();
    const isLoading = useStoreState((state) => state.auth.loginLoading);
    const token = props.match.params.token;

    const confirmPasswordResetAction = useStoreActions(
        (actions) => actions.auth.confirmPasswordReset
    );

    const onSubmit = (
        values: InitialDataConfirmPasswordResetForm,
        helper: any
    ) => {
        confirmPasswordResetAction({ ...values, token })
            .then(() => history.push("/"))
            .catch((error) => setBackendErrors(helper, error));
    };

    return (
        <BaseUnauthenticatedLayout>
            <Typography.Title>
                {t("users.auth.confirmPasswordReset.title")}
            </Typography.Title>
            <ConfirmPasswordResetForm
                onSubmit={onSubmit}
                isLoading={isLoading}
            />
        </BaseUnauthenticatedLayout>
    );
};
