import * as React from "react";

import { MailOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { t } from "../../../../translation";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Button } from "antd";
import * as Yup from "yup";
import { StyleSheet, css } from "aphrodite";

interface IProps {
    onSubmit: (values: InitialDataPasswordResetForm) => void;
    isLoading: boolean;
}

const initialData = {
    email: "",
};

export type InitialDataPasswordResetForm = typeof initialData;

const styles = StyleSheet.create({
    button: {
        width: "100%",
    },
});

export const PasswordResetForm: React.FC<IProps> = (props: IProps) => {
    const history = useHistory();

    return (
        <Formik<InitialDataPasswordResetForm>
            initialValues={initialData}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .required()
                    .email(),
            })}
        >
            <Form>
                <Form.Item name="email">
                    <Input
                        name="email"
                        prefix={
                            <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder={t("users.fields.email")}
                    />
                </Form.Item>
                <Form.Item name="submit">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={props.isLoading}
                        className={css(styles.button)}
                    >
                        {t("buttons.request")}
                    </Button>
                </Form.Item>
                <Form.Item name="login">
                    <Button
                        type="default"
                        loading={props.isLoading}
                        onClick={() =>
                            history.push(getUnAuthenticatedPath("login"))
                        }
                        className={css(styles.button)}
                    >
                        {t("users.auth.title")}
                    </Button>
                </Form.Item>
            </Form>
        </Formik>
    );
};
