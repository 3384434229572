export const scheduleTranslationNL = {
    title: "Planning",
    status: {
        open: {
            title: "Nog niet verstuurd",
            description:
                "De planning van deze week is nog niet verstuurd. " +
                "Wanneer deze is verstuurd veranderd de status naar 'Afwachtend op reactie'.",
        },
        pending: {
            title: "Afwachtend op reactie",
            description:
                "De planning van deze week is al verstuurd en kan niet meer gewijzigd worden. " +
                "Als de planning geaccepteerd of geweigerd is veranderd de status naar geaccepteerd of geweigerd.",
        },
        accepted: {
            title: "Geaccepteerd",
            description: "De planning voor deze week is geaccepteerd!",
        },
        rejected: {
            title: "Afgewezen",
            description: "De planning voor deze week is afgewezen.",
        },
        blocked: {
            title: "Geblokeerd",
            description:
                "Planningen kunnen t/m donderdag verstuurd worden voor de volgende week. " +
                "Wacht tot maandag om de nieuwe planning te maken.",
        },
    },
    confirm: {
        text:
            "Weet u zeker dat u de planning wilt versturen? Als de planning verstuurd kan deze niet meer aangepast worden.",
    },
    help: {
        title: "Help?",
        weekPicker:
            "Hier kunt u de week selecteren waarvoor u de planning wilt maken!",
        scheduleForm:
            "Dit is de complete planning voor de geselecteerde week. Hier kunt u medewerkers gaan plannen voor deze week.",
        scheduleStatus:
            "Dit is de status van de planning van de geselecteerde week. Deze laat zien of de planning al verstuurd is en of deze geaccepteerd of geweigerd is.",
        employeeSelect:
            "Hier kunt u een medewerker selecteren die u wilt plannen voor de bovenstaande datum!",
        editButton:
            "Hier kunt u de planning opslaan voor de geselecteerde week. " +
            "Op dit wordt de planning alleen opgeslagen, nog niet verstuurd. " +
            "Dit zodat u later de planning nog verder aan kan passen.",
        sendButton:
            "Hier kunt u een de planning verzenden. Deze verzend de huidige planning. " +
            "Als de planning eenmaal is verzonden kan deze niet meer aangepast worden.",
        finish: "Start met plannen!",
    },
};
