import { ID } from "../../../shared/interfaces";
import { SubState } from "../../../shared/normalizer";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getItemsSelector } from "../../normalized/selectors/getItemsSelector";
import * as React from "react";

export const useNormalizedState = <T extends { id: ID }>(
    subState: SubState
): T[] => {
    const { getItems } = useStoreActions((actions) => actions.normalize);
    const items: T[] = useStoreState((state) =>
        getItemsSelector(state, subState)
    );
    const itemDone = useStoreState(
        (state) => state.normalize.meta[subState].get.done
    );
    const currentUser = useStoreState((state) => state.auth.currentUser);

    React.useEffect(() => {
        if (
            !itemDone &&
            currentUser &&
            currentUser.hasPermission(subState, "view")
        ) {
            getItems({ subState });
        }
    }, []);

    return items;
};
