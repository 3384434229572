import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { IAuthState } from "../state";
import { Notifier } from "../../../shared/functions";

interface IInputData {
    token: string;
    password: string;
    passwordConfirm: string;
}

export const confirmPasswordResetThunk: Thunk<
    IAuthState,
    IInputData,
    any,
    RootState,
    Promise<void>
> = thunk(async (actions, payload) => {
    actions.confirmPasswordResetBegin();

    return HttpClient.post<{}>(`/api/auth/reset_confirm/`, payload)
        .then(() => {
            actions.confirmPasswordResetSuccess();
            Notifier.success("users.auth.confirmPasswordReset.success");
        })
        .catch((error) => {
            actions.confirmPasswordResetFailure(error);
            if (error.response && error.response.status === 404) {
                Notifier.error("users.auth.confirmPasswordReset.notFound");
            } else {
                Notifier.error("http.crud.update.error");
            }
            throw error;
        });
});
