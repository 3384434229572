import * as React from "react";
import { Col, Row } from "antd";

import { StyleSheet, css } from "aphrodite";
import "./baseActionBar.less";

const styles = StyleSheet.create({
    fullHeight: {
        height: "100%",
    },
});

export const BaseActionBar: React.FC = (props) => (
    <>
        <div className="action-bar shadow-outline">
            <Row gutter={16} align="middle" className={css(styles.fullHeight)}>
                {React.Children.map(props.children, (child) => (
                    <Col>{child}</Col>
                ))}
            </Row>
        </div>
    </>
);
